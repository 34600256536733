import React from 'react';

import {
  getOrderProductState,
  isTripSecondDriverService,
  type OrderProduct,
  type SelectedOrderProduct,
  type SelectedOrderProducts,
} from '../../model/OrderProduct';
import type { OrderChangeData } from '../../store/order';
import { SecondDriverSummarySection } from '../Insurance/SecondDriver';

interface Props {
  services: OrderProduct[];
  selected: SelectedOrderProducts;

  onChange(data: OrderChangeData): unknown;
}

export default function AdditionalServicesSection(props: Props) {
  const handleChange = (service: SelectedOrderProduct) => {
    props.onChange({ selectedProduct: service });
  };

  const handleRemove = (serviceType: keyof SelectedOrderProducts) => {
    props.onChange({ unselectedProduct: serviceType });
  };

  if (!props.services.length) return null;

  return (
    <>
      {props.services.map(service => {
        if (isTripSecondDriverService(service)) {
          const state = getOrderProductState(service, props.selected);
          return (
            <SecondDriverSummarySection
              key={service.id}
              onChange={handleChange}
              onRemove={() => handleRemove(service.type)}
              service={service}
              state={state}
            />
          );
        }
        return null;
      })}
    </>
  );
}
