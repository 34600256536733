import React from 'react';

import { type Datelike, formatDatelike } from '../../services/DateTime';
import T from '../Translate';

interface Props {
  cancelUntil: Datelike;
  ns: string;
}

export default function CancellationPolicy({ cancelUntil, ns }: Props) {
  return (
    <div className="bg-success-light rounded p-4 mt-2">
      <T as="h6" className="mb-3" id={`${ns}.cancellation.title`} />

      <div className="d-flex">
        <i className="icon icon-round-info text-success mr-3 mt-1 d-block" />
        <T
          as="p"
          className="d-block mb-0"
          context={cancelUntil ? undefined : 'notime'}
          data={{ cancelUntil: formatDatelike(cancelUntil, 'd. MM. HH:mm') }}
          html
          id={`${ns}.cancellation.text`}
          multiline
        />
      </div>
    </div>
  );
}
