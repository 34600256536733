import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { OrderQuery } from '../../model/Order';
import type { ReduxState } from '../../store';
import { loadOrderDetail } from '../../store/order';
import DotIcon from '../DotIcon';
import Loader from '../Loader';
import MetaTags from '../MetaTags';
import { RestrictionProvider } from '../Restriction';
import Switch from '../Switch';

import OrderPaymentPage from './OrderPaymentPage';
import OrderSuccessPage from './OrderSuccessPage';
import OrderSummaryPage from './OrderSummaryPage';

interface Props {
  hash: string;
  query: OrderQuery;
}

enum OrderStage {
  SUMMARY = 0,
  PAYMENTS = 1,
  ERROR = 2,
  SUCCESS = 3,
}

export default function OrderPage({ hash, query }: Props) {
  const dispatch: DispatchFunction = useDispatch();
  const { error, loading, region, vehicleName } = useSelector((state: ReduxState) => ({
    error: state.order.error,
    loading: state.order.loading,
    region: state.order.region,
    vehicleName: state.order.trip?.vehicle.name,
  }));
  const [stage, setStage] = useState(OrderStage.SUMMARY);

  useEffect(() => {
    dispatch(loadOrderDetail(hash, query));
  }, [hash, query]);

  if (error) {
    return (
      <main className="main-container container container--top py-5">
        <DotIcon icon="round-warning" text={error} />
      </main>
    );
  }

  if (loading) {
    return (
      <main className="main-container container container--top py-5">
        <Loader className="my-5 py-5" />
      </main>
    );
  }

  return (
    <RestrictionProvider region={region}>
      <MetaTags title="order.meta.title" titleData={{ vehicleName }} />
      <Switch scrollToTop value={stage}>
        <OrderSummaryPage case={OrderStage.SUMMARY} onComplete={() => setStage(OrderStage.PAYMENTS)} />
        <OrderPaymentPage
          case={OrderStage.PAYMENTS}
          onBack={() => setStage(OrderStage.SUMMARY)}
          onComplete={() => setStage(OrderStage.SUCCESS)}
        />
        <OrderSuccessPage case={OrderStage.SUCCESS} />
      </Switch>
    </RestrictionProvider>
  );
}
