import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import Tracking from '../../services/Tracking';
import type { ReduxState } from '../../store';
import { SubmitButton } from '../Input/SubmitButton';
import Loader from '../Loader';
import MobileModal from '../MobileModal';
import T from '../Translate';
import { DriverPricingBreakdown } from '../Trip/PricingBreakdown';

import styles from './priceDetail.module.scss';

interface Props {
  opened: boolean;

  onClose(): unknown;
}

export default function PriceDetail({ opened, onClose }: Props) {
  const { order } = useSelector((store: ReduxState) => ({
    order: store.order,
  }));

  const loading = order.updating;

  return (
    <MobileModal
      className={cn(styles.container, 'aside-grid__aside show-on-desktop')}
      containerClassName="container"
      onClose={onClose}
      onOpen={() => Tracking.track('BOOKING_PRICE_DETAIL_VIEWED')}
      opened={opened}
    >
      <div className={styles.header}>
        <T as="h2" className={styles.title} id="booking.priceDetail.title" />
      </div>

      <DriverPricingBreakdown
        className={styles.box}
        loading={loading}
        mileage={order.trip.total_mileage}
        pricePerDay={order.trip.price_per_day}
        pricePerKm={order.trip.vehicle.prices.price_per_km}
        pricingBreakdown={order.trip.pricing_breakdown}
        tripDays={order.trip.trip_length}
        tripPrice={order.trip.pricing.trip_price}
        // longTermDiscountPercent={order.trip.percentage_long_term_discount}
        // longTermDiscount={order.trip.long_term_discount}
      >
        <SubmitButton block className={styles.submitButton} lg primary track="BOOKING_PRICE_DETAIL_SUBMIT_CLICKED">
          {loading ? <Loader /> : <T id="booking.priceDetail.submit" />}
        </SubmitButton>
      </DriverPricingBreakdown>

      {order.trip.vehicle.instant_booking ? (
        <div className={styles.info}>
          <i className="icon icon-lightning" />
          <T as="span" id="booking.priceDetail.notes.instantBooking" />
        </div>
      ) : null}
      <div className={styles.info}>
        <i className="icon icon-round-info" />
        <T as="span" id="booking.priceDetail.notes.creditCheck" />
      </div>
      {!order.trip.vehicle.instant_booking ? (
        <div className={styles.info}>
          <i className="icon icon-round-info" />
          <T as="span" id="booking.priceDetail.notes.paymentAfterConfirm" />
        </div>
      ) : null}
    </MobileModal>
  );
}
