import React from 'react';
import cn from 'classnames';

import styles from './Checkbox.module.scss';

interface Props {
  name: string;
  value: string;
  checked: boolean;
  children: React.ReactNode;
  className?: string;
  error?: boolean | string | React.ReactNode;

  onChange(checked: boolean, event: React.FormEvent<HTMLInputElement>): void;
}

export function Checkbox(props: Props) {
  return (
    <label className={cn(styles.checkbox, { checked: props.checked, error: !!props.error }, props.className)}>
      <div className={styles.checkboxIndicator}>
        <i className="icon icon-check" />
      </div>
      <input
        checked={props.checked}
        name={props.name}
        onChange={e => props.onChange(e.target.checked, e)}
        type="checkbox"
        value={props.value}
      />
      <div className={styles.label}>{props.children}</div>
      {props.error && typeof props.error !== 'boolean' ? <div className={styles.error}>{props.error}</div> : null}
    </label>
  );
}
