import React from 'react';
import cn from 'classnames';

import styles from './icon.module.scss';

interface Props {
  className?: string;
}

export function LightningOutlinedIcon(props: Props) {
  return (
    <div className={cn(styles.lightningOIcon, props.className)}>
      <svg viewBox="0 0 14 16">
        <path d="M8.328,1.416l-0.573,4.584l5.245,-0c0.259,-0 0.494,0.15 0.604,0.384c0.109,0.234 0.074,0.511 -0.092,0.709l-6.667,8c-0.189,0.228 -0.506,0.303 -0.777,0.185c-0.272,-0.118 -0.433,-0.4 -0.396,-0.694l0.573,-4.584l-5.245,-0c-0.259,-0 -0.494,-0.15 -0.604,-0.384c-0.109,-0.234 -0.074,-0.511 0.092,-0.709l6.667,-8c0.189,-0.228 0.506,-0.303 0.777,-0.185c0.272,0.118 0.433,0.4 0.396,0.694Zm-1.605,2.091l-4.3,5.16l4.577,-0c0.191,-0 0.373,0.082 0.5,0.225c0.126,0.143 0.185,0.334 0.162,0.524l-0.385,3.077l4.3,-5.16l-4.577,0c-0.191,0 -0.373,-0.082 -0.5,-0.225c-0.126,-0.144 -0.185,-0.334 -0.162,-0.524l0.385,-3.077Z" />
      </svg>
    </div>
  );
}
