import React from 'react';
import cn from 'classnames';

import styles from './icon.module.scss';

interface Props {
  className?: string;
}

export function InfoCircleIcon(props: Props) {
  return (
    <div className={cn(styles.icon, props.className)}>
      <svg fill="none" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.4 3H6.6V4.2H5.4V3ZM5.4 5.4H6.6V9H5.4V5.4ZM6 0C2.688 0 0 2.688 0 6C0 9.312 2.688 12 6 12C9.312 12 12 9.312 12 6C12 2.688 9.312 0 6 0ZM6 10.8C3.354 10.8 1.2 8.646 1.2 6C1.2 3.354 3.354 1.2 6 1.2C8.646 1.2 10.8 3.354 10.8 6C10.8 8.646 8.646 10.8 6 10.8Z" />
      </svg>
    </div>
  );
}
