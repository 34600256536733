import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Braintree, HostedField } from 'react-braintree-fields';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import {
  type BraintreePaymentMethod,
  type BraintreeTokenizer,
  getBraintreePaymentMethods,
  pairCard,
  unpairCard,
} from '../../services/Braintree';
import type { Country } from '../../services/Country';
import Tracking from '../../services/Tracking';
import { flashMessageError } from '../../store/session';
import Detached from '../Detached';
import { Checkbox } from '../Form/CheckboxField';
import FormGroup from '../Form/FormGroup';
import { SubmitButton } from '../Form/SubmitButton';
import { ValidationMessage } from '../Form/ValidationMessage';
import type { FieldError } from '../Input/useField';
import Loader from '../Loader';
import Modal from '../Modal';
import T from '../Translate';

import styles from './paymentMethod.module.scss';

interface Props {
  clientToken: string;
  country: Country;
  title: string;
  className?: string;
  value: BraintreePaymentMethod;
  error?: FieldError;

  onChange(value: BraintreePaymentMethod): void;
}

export default function PaymentMethodSection(props: Props) {
  const dispatch = useDispatch();
  const [methods, setMethods] = useState<BraintreePaymentMethod[]>([]);
  const [loading, setLoading] = useState(true);
  const [addingMethod, setAddingMethod] = useState(false);

  useEffect(() => {
    async function loadMethods() {
      try {
        const methods = await getBraintreePaymentMethods(props.clientToken);
        setMethods(methods);
        props.onChange(methods.length ? methods[0] : null);
        setLoading(false);
      } catch (error) {
        dispatch(flashMessageError(error.message));
        setLoading(false);
      }
    }

    if (!props.clientToken) return;
    loadMethods();
  }, [props.clientToken]);

  const handleAddMethod = useCallback(
    async (nonce: string) => {
      try {
        setLoading(true);
        const methods = await getBraintreePaymentMethods(props.clientToken);
        setMethods(methods);
        let selectedMethod = methods.find(it => it.nonce === nonce);
        if (!selectedMethod) {
          selectedMethod = methods[0];
        }
        props.onChange(selectedMethod);
        setAddingMethod(false);
      } catch (err) {
        dispatch(flashMessageError(err.message));
      }
      setLoading(false);
    },
    [props.clientToken],
  );

  const handleRemove = useCallback(
    async (method: BraintreePaymentMethod) => {
      try {
        await unpairCard(method.nonce, props.country);
        const methods = await getBraintreePaymentMethods(props.clientToken);
        setMethods(methods);
        props.onChange(methods.length ? methods[0] : null);
      } catch (error) {
        dispatch(flashMessageError(error.message));
      }
    },
    [props.clientToken, props.country],
  );

  return (
    <section className={props.className}>
      <T as="h4" id={props.title} />

      {loading ? (
        <Loader className="my-5 py-5" />
      ) : (
        <div className="d-flex flex-column flex-auto">
          {methods.map(method => (
            <div className={styles.paymentMethod} key={method.nonce}>
              <Checkbox
                checked={props.value?.nonce === method.nonce}
                className={styles.checkbox}
                onChange={() => props.onChange(method)}
                primary
                value={method.nonce}
              >
                <T
                  data={{
                    cardType: method.details.cardType,
                    last4Digits: method.details.lastFour,
                  }}
                  html
                  id="booking.summary.payment.card"
                />
              </Checkbox>
              <button
                className="btn btn-link"
                onClick={() => {
                  handleRemove(method);
                  Tracking.track('BOOKING_PAYMENT_CARD_REMOVE_CLICKED');
                }}
                type="button"
              >
                <T id="booking.summary.payment.remove" />
              </button>
            </div>
          ))}
          <div className={styles.paymentMethod}>
            <button
              className="btn btn-link"
              onClick={() => {
                setAddingMethod(true);
                Tracking.track('BOOKING_PAYMENT_CARD_ADD_CLICKED');
              }}
              type="button"
            >
              <T id="booking.summary.payment.add" />
            </button>
          </div>
        </div>
      )}

      {props.error ? (
        <div className={cn(styles.error, 'error')}>
          {typeof props.error === 'string' ? <T id={props.error} optional /> : props.error}
        </div>
      ) : null}

      <AddPaymentMethodModal
        clientToken={props.clientToken}
        country={props.country}
        onClose={() => setAddingMethod(false)}
        onSubmit={handleAddMethod}
        opened={addingMethod}
      />
    </section>
  );
}

interface ModalProps {
  opened: boolean;
  clientToken: string;
  country: Country;

  onClose(): void;

  onSubmit(nonce: string): void;
}

function AddPaymentMethodModal({ opened, clientToken, country, onClose, onSubmit }: ModalProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>(null);
  const tokenize = useRef<BraintreeTokenizer>(null);

  const handleAddMethod = useCallback(async () => {
    try {
      setLoading(true);
      const { nonce } = await tokenize.current();
      await pairCard(nonce, country);
      onSubmit(nonce);
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  }, [clientToken, country]);

  if (!opened) return null;

  return (
    <Detached container="add-payment-method">
      <Modal
        closeModal={onClose}
        header={
          <h5 className="flex-grow-1 text-center">
            <T id="booking.payment.add.title" />
          </h5>
        }
        size="md"
      >
        <div className="modal-body">
          <T as="p" id="booking.payment.add.description" multiline />
          <div className="credit-card-wrapper">
            <Braintree
              authorization={clientToken}
              className="credit-card credit-card-input"
              getTokenRef={t => {
                tokenize.current = t;
              }}
              styles={{
                input: { 'font-size': '16px', color: '#F22D2D' },
                '.valid': { color: '#495057' },
              }}
            >
              <div className="credit-card__head">
                <FormGroup hideValidation id="credit-card-number" label="booking.payment.add.number.placeholder">
                  <HostedField className="form-control" id="credit-card-number" type="number" />
                </FormGroup>
              </div>
              <div className="credit-card__body">
                <div className="row">
                  <FormGroup
                    className="col-6"
                    id="credit-card-expiration"
                    label="booking.payment.add.expirationDate.placeholder"
                  >
                    <div className="form-row">
                      <div className="col">
                        <HostedField className="form-control" id="credit-card-expiration" type="expirationMonth" />
                      </div>
                      <div className="col">
                        <HostedField className="form-control" type="expirationYear" />
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup
                    className="col-3 offset-3"
                    id="credit-card-cvv"
                    label="booking.payment.add.cvv.placeholder"
                  >
                    <HostedField className="form-control" id="credit-card-cvv" type="cvv" />
                  </FormGroup>
                </div>
              </div>
            </Braintree>
          </div>
          <ValidationMessage message={error} show={error !== null} />
          <SubmitButton
            className="btn-block btn-primary mt-4"
            loading={loading}
            onClick={handleAddMethod}
            type="button"
          >
            <T id="booking.payment.add.submit" />
          </SubmitButton>
        </div>
      </Modal>
    </Detached>
  );
}
