import React, { useCallback, useState } from 'react';

import {
  isProductSelected,
  isTripCO2CompensationService,
  isTripInsurance,
  type SelectedOrderProducts,
  type TripCO2CompensationService,
  type TripInsurance,
} from '../../model/OrderProduct';
import Tracking from '../../services/Tracking';
import type { OrderChangeData } from '../../store/order';
import ProductCard from '../Insurance/ProductCard';
import { ProductCheckbox } from '../Insurance/ProductCheckbox';
import Modal from '../Modal';
import T from '../Translate';

import styles from './order.module.scss';

interface Props {
  services: (TripInsurance | TripCO2CompensationService)[];
  selected: SelectedOrderProducts;

  onChange(data: OrderChangeData): unknown;
}

export default function ServicesSection(props: Props) {
  const [opened, setOpened] = useState(false);

  const handleChange = useCallback(
    (product: TripInsurance | TripCO2CompensationService) => {
      if (isProductSelected(product, props.selected)) {
        props.onChange({ unselectedProduct: product.type });
      } else {
        props.onChange({
          selectedProduct: { id: product.id, type: product.type },
        });
      }
    },
    [props.selected],
  );

  if (!props.services.length) return null;

  return (
    <section>
      <T as="h4" id="booking.summary.services.title" />
      <div className={styles.productList}>
        {props.services.map(service => (
          <ProductCheckbox
            checked={isProductSelected(service, props.selected)}
            key={service.type}
            name={isTripInsurance(service) ? service.name : service.title}
            onChange={() => handleChange(service)}
            price={service.price}
            recommended={isTripInsurance(service) && service.recommended}
            value={service.type}
          />
        ))}
      </div>

      <button
        className="btn btn-link mt-3"
        onClick={() => {
          setOpened(true);
          Tracking.track('BOOKING_INSURANCE_DETAIL_CLICKED');
        }}
        type="button"
      >
        <T as="span" id="booking.summary.services.compare" />
        <i className="icon icon-arrow-right text-decoration-none text-muted ml-3" />
      </button>

      <Modal
        closeModal={() => setOpened(false)}
        header={
          <h5 className="flex-grow-1 text-center">
            <T id="booking.services.detail.title" />
          </h5>
        }
        isOpen={opened}
        onOpen={() => Tracking.track('BOOKING_INSURANCE_DETAIL_VIEWED')}
        size="lg"
      >
        <div className="modal-body">
          <T as="p" className="text-center text-muted" id="booking.services.detail.description" multiline />

          <div className={styles.productModalBody}>
            {props.services.map(service => {
              if (isTripCO2CompensationService(service)) {
                return (
                  <ProductCard
                    description={service.description}
                    description_list={service.description_list}
                    key={service.id}
                    onClick={() => handleChange(service)}
                    price={service.price}
                    selected={isProductSelected(service, props.selected)}
                    title={service.title}
                    type={service.type}
                  />
                );
              }
              return (
                <ProductCard
                  deposit={service.deposit}
                  description={service.description}
                  description_list={service.description_list}
                  key={service.id}
                  onClick={() => handleChange(service)}
                  price={service.price}
                  recommended={service.recommended}
                  selected={isProductSelected(service, props.selected)}
                  title={service.name}
                  type={service.type}
                />
              );
            })}
          </div>
        </div>
      </Modal>
    </section>
  );
}
