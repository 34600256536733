import React from 'react';
import { useRef } from 'react';
import cn from 'classnames';
import { v4 as uuid } from 'uuid';

import Field, { type FieldAttributes } from './Field';
import FieldValidationMessage from './ValidationMessage';

interface Shared {
  id?: string;
  className?: string;
  description?: React.ReactNode;
  primary?: boolean;
}

interface Props extends React.InputHTMLAttributes<HTMLInputElement>, Shared {}

export function Checkbox({ primary, className, children, description, ...props }: Props) {
  const id = useRef(props.id ?? uuid());
  return (
    <div className={cn('pure-checkbox', className, { primary })}>
      <input id={id.current} type="checkbox" {...props} />
      <label htmlFor={id.current}>{children}</label>
      {description ? <div className="pure-checkbox--description">{description}</div> : null}
    </div>
  );
}

interface FieldProps extends FieldAttributes<boolean>, Shared {
  name: string;
  children: string | React.ReactNode;
  showValidation?: boolean;
}

export default function CheckboxField({
  name,
  children,
  className,
  showValidation,
  description,
  primary,
  ...props
}: FieldProps) {
  const id = useRef(props.id ?? uuid());
  return (
    <div className={cn('pure-checkbox', className, { primary })}>
      <Field id={id.current} name={name} type="checkbox" {...props} />
      <label htmlFor={id.current}>{children}</label>
      {description ? <div className="pure-checkbox--description">{description}</div> : null}
      {showValidation ? <FieldValidationMessage name={name} /> : null}
    </div>
  );
}
