import React, { useMemo } from 'react';
import cn from 'classnames';

import { OrderProductType } from '../../model/OrderProduct';
import type { PriceType } from '../../model/Price';
import Price from '../Price';
import T from '../Translate';

import styles from './products.module.scss';

interface Props {
  title: string;
  type: OrderProductType | string;
  price?: PriceType;
  deposit?: PriceType;
  recommended?: boolean;
  description: string;
  description_list?: {
    type: 'text' | 'link';
    content: string;
  }[];
  selected: boolean;
  className?: string;
  hidePrice?: boolean;

  onClick(): void;

  onLinkClick?(): void;
}

export default function ProductCard({ selected, ...props }: Props) {
  const showDeposit = useMemo(() => props.deposit && props.deposit.amount > 0, [props.deposit]);

  return (
    <button className={cn(styles.productCard, { selected }, props.className)} onClick={props.onClick} type="button">
      <div className={styles.aside}>
        <i className="icon icon-check" />
      </div>
      <div className={styles.content}>
        <h5 className={styles.title}>
          <span>{props.title}</span>
          {props.type !== OrderProductType.OWN_INSURANCE && !props.hidePrice && props.price ? (
            <Price as="small" price={props.price} />
          ) : null}
        </h5>
        {showDeposit ? (
          <div className={styles.deposit}>
            <T as="span" id="insurance_refundable_deposit" />
            <Price as="span" price={props.deposit} />
          </div>
        ) : null}
        {props.recommended ? <T className={styles.recommended} id="insurance_recommended" /> : null}
        <p dangerouslySetInnerHTML={{ __html: props.description }} />
        <ul>
          {props.description_list.map((item, index) => (
            <DescriptionItem key={index} {...item} onLinkClick={props.onLinkClick} />
          ))}
        </ul>
      </div>
    </button>
  );
}

interface ItemProps {
  type: 'text' | 'link';
  content: string;

  onLinkClick?(): void;
}

function DescriptionItem({ type, content, onLinkClick }: ItemProps) {
  if (type === 'link') {
    const [text, link] = content.split('|');
    return (
      <li>
        <a
          dangerouslySetInnerHTML={{ __html: text }}
          href={link}
          onClick={onLinkClick}
          rel="noopener noreferrer"
          target="_blank"
        />
      </li>
    );
  }

  return (
    <li>
      <span dangerouslySetInnerHTML={{ __html: content }} />
    </li>
  );
}
