import React from 'react';
import cn from 'classnames';

import styles from './Radio.module.scss';

export interface RadioProps<T extends string = string>
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'> {
  value: T;
  checked: boolean;
  name: string;
  error?: boolean;
  children: string | React.ReactNode;

  onChange(value: T): void | Promise<void>;
}

export function Radio<T extends string = string>({
  value,
  checked,
  error,
  onChange,
  children,
  className,
  ...props
}: RadioProps<T>) {
  return (
    <label className={cn(styles.radio, { checked, error }, className)}>
      <input
        {...props}
        checked={checked}
        name={props.name}
        onChange={event => onChange(event.target.value as T)}
        type="radio"
        value={value}
      />
      <div className={styles.label}>{children}</div>
      <div className={styles.radioIndicator} />
    </label>
  );
}
