import React from 'react';
import type { PageProps } from 'gatsby';
import queryString from 'query-string';

import type { OrderQuery } from '../../model/Order';
import PageLayout from '../../views/Layout/Page';
import Order from '../../views/Order/OrderPage';

export default function OrderPage({ params, location }: PageProps) {
  const query: OrderQuery = queryString.parse(location.search);

  return (
    <PageLayout pageview="booking" private>
      <Order hash={params.hash} query={query} />
    </PageLayout>
  );
}
