import React from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { Form, FormikProvider, useFormik } from 'formik';
import { object, string } from 'yup';

import type {
  OrderProductType,
  SecondDriverData,
  SelectedOrderProduct,
  TripSecondDriverService,
} from '../../model/OrderProduct';
import { flashMessageSuccess } from '../../store/session';
import Button from '../Button';
import { FormGroupField } from '../Form/FormGroup';
import FormikSubmitButton from '../Form/SubmitButton';
import { InfoCircleIcon } from '../Icons/InfoCircleIcon';
import MobileModal from '../MobileModal';
import Price from '../Price';
import T, { TProvider } from '../Translate';

import styles from './additionalServices.module.scss';

interface SectionProps {
  service: TripSecondDriverService;
  state?: SelectedOrderProduct<OrderProductType.SECOND_DRIVER, SecondDriverData>;

  onChange(state: SelectedOrderProduct<OrderProductType.SECOND_DRIVER, SecondDriverData>): void;

  onRemove(): void;
}

export function SecondDriverSummarySection({ service, state, ...props }: SectionProps) {
  const dispatch = useDispatch();
  const [opened, setOpened] = React.useState(false);
  return (
    <section className={styles.section}>
      <TProvider prefix="booking_summary_second_driver" prefixSeparator="_">
        <h4>{service.title}</h4>
        <div className={styles.info}>
          <span>{state?.data ? `${state.data.first_name} ${state.data.last_name}` : service.title}</span>
          <Price as="span" price={service.price} />
        </div>
        <Button onClick={() => setOpened(true)} variant="link">
          <T id={state?.data ? 'edit_btn' : 'add_btn'} />
        </Button>
        <div className={styles.hint}>
          <InfoCircleIcon className={styles.icon} />
          <T as="p" id="hint" />
        </div>
      </TProvider>

      <SecondDriverServiceForm
        onCancel={() => setOpened(false)}
        onRemoveClick={() => {
          props.onRemove();
          setOpened(false);
        }}
        onSubmit={data => {
          props.onChange({ id: service.id, type: service.type, data });
          dispatch(flashMessageSuccess('booking_summary_second_driver_success_msg'));
          setOpened(false);
        }}
        opened={opened}
        showRemoveButton={!!state?.data}
        state={state?.data}
      />
    </section>
  );
}

interface FormProps {
  state: Partial<SecondDriverData>;
  className?: string;
  showRemoveButton: boolean;
  opened: boolean;

  onCancel(): void;

  onRemoveClick(): void;

  onSubmit(state: SecondDriverData): void | Promise<void>;
}

export function SecondDriverServiceForm(props: FormProps) {
  const [opened, setOpened] = React.useState(false);
  const formik = useFormik<SecondDriverData>({
    initialValues: {
      first_name: props.state?.first_name ?? '',
      last_name: props.state?.last_name ?? '',
      dl_number: props.state?.dl_number ?? '',
      email: props.state?.email ?? '',
    },
    validationSchema: object().shape({
      first_name: string().required('global.required'),
      last_name: string().required('global.required'),
      dl_number: string().required('global.required'),
      email: string().email('global.email.invalid').required('global.required'),
    }),
    async onSubmit(values) {
      try {
        await props.onSubmit(values);
      } catch (error) {
        console.error(error); // TODO: handle error
      }
    },
  });

  const handleRemoveClick = () => {
    props.onRemoveClick();
    setOpened(false);
  };

  return (
    <TProvider prefix="additional_services_second_driver" prefixSeparator="_">
      <MobileModal detached onClose={() => props.onCancel()} opened={props.opened && !opened} size="md">
        <div className="modal-body">
          <FormikProvider value={formik}>
            <Form className={cn(styles.secondDriver, props.className)}>
              <T as="h4" id="title" />
              <T as="p" id="description" />

              <div className={styles.hint}>
                <InfoCircleIcon className={styles.icon} />
                <T as="p" id="hint" />
              </div>

              <FormGroupField label="first_name_label" name="first_name" type="text" />
              <FormGroupField label="last_name_label" name="last_name" type="text" />
              <FormGroupField label="dl_number_label" name="dl_number" type="text" />
              <FormGroupField label="email_label" name="email" type="email" />

              <div className={styles.buttons}>
                {props.showRemoveButton && (
                  <Button icon="trash" iconRight onClick={() => setOpened(true)} variant="link">
                    <T id="remove_btn" />
                  </Button>
                )}
                <FormikSubmitButton>
                  <T id="submit_btn" />
                </FormikSubmitButton>
              </div>
            </Form>
          </FormikProvider>
        </div>
      </MobileModal>
      <MobileModal detached onClose={() => setOpened(false)} opened={props.opened && opened} size="md">
        <div className={cn(styles.modal, 'modal-body')}>
          <T as="h4" className={styles.title} id="remove_title" />
          <T as="p" className={styles.description} id="remove_description" />

          <div className={styles.modalButtons}>
            <Button className={styles.cancelButton} onClick={() => setOpened(false)}>
              <T id="remove_cancel_btn" />
            </Button>
            <Button onClick={handleRemoveClick} variant="primary">
              <T id="remove_confirm_btn" />
            </Button>
          </div>
        </div>
      </MobileModal>
    </TProvider>
  );
}
