import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormikProvider, useFormik } from 'formik';
import { object } from 'yup';

import { useOnLoadTracking } from '../../hooks/useTracking';
import { formatPrice } from '../../model/Price';
import { isTripInstantBooking } from '../../model/Trip';
import type { BraintreePaymentMethod } from '../../services/Braintree';
import { formatDatelike, hoursTill } from '../../services/DateTime';
import Tracking from '../../services/Tracking';
import type { ReduxState } from '../../store';
import { createTripOrder } from '../../store/order';
import FormikSubmitButton from '../Form/SubmitButton';
import { StaticImage } from '../Image';
import List, { ListItem } from '../List';
import Switch, { type SwitchChildrenProps } from '../Switch';
import T from '../Translate';

import PaymentMethodSection from './PaymentMethodSection';

import styles from './order.module.scss';

interface Props {
  onComplete(): void;

  onBack(): unknown;
}

export default function OrderPaymentPage(props: Props & SwitchChildrenProps<number>) {
  const dispatch: DispatchFunction = useDispatch();
  const {
    trip,
    totalPrice,
    deposit,
    paymentMethod,
    clientToken,
    region,
    isInstantBooking,
    hoursTillStart,
    confirmUntil,
    cancelUntil,
  } = useSelector((store: ReduxState) => ({
    trip: store.order.trip,
    totalPrice: formatPrice(store.order.trip.price_total, store.app.language),
    deposit: formatPrice(store.order.trip.pricing.deposit, store.app.language),
    paymentMethod: store.order.paymentMethod,
    clientToken: store.order.client_token,
    region: store.order.region,
    isInstantBooking: isTripInstantBooking(store.order.trip),
    hoursTillStart: hoursTill(store.order.trip.date_from),
    confirmUntil: formatDatelike(store.order.trip.confirm_to, 'd.M.yyyy HH:mm'),
    cancelUntil: formatDatelike(store.order.trip.free_cancellation_time, 'd.M.yyyy HH:mm'),
  }));
  const [error, setError] = useState(false);
  useOnLoadTracking(() => Tracking.track('BOOKING_PAYMENT_VIEWED', trip));

  const formik = useFormik<{ paymentMethod: BraintreePaymentMethod }>({
    validationSchema: object({
      paymentMethod: object().required('global.required'),
    }),
    initialValues: {
      paymentMethod,
    },
    async onSubmit({ paymentMethod }) {
      try {
        Tracking.track('BOOKING_PAYMENT_SUBMITTED', trip);
        await dispatch(createTripOrder(paymentMethod));
        props.onComplete();
      } catch (error) {
        Tracking.track('BOOKING_PAYMENT_FAILED', error);
        setError(true);
      }
    },
  });

  return (
    <main className="main-container container--top container--bottom">
      <FormikProvider value={formik}>
        <Form className="container">
          <i className="icon icon-lg icon-arrow-back text-muted cursor-pointer" onClick={() => props.onBack()} />

          <div className="row">
            <div className="col-12 col-lg-10 offset-lg-1 col-xl-6 offset-xl-3 d-flex flex-column align-items-center">
              <StaticImage alt="illustration" name="booking-payment" width={300} />

              <T
                as="h1"
                className="mt-2 mb-5 text-center"
                context={isInstantBooking ? 'instantBooking' : null}
                id="booking.paymentInfo.title"
              />

              <Switch value={isInstantBooking}>
                <InstantBookingPaymentPage cancelUntil={cancelUntil} case={true} />
                <>
                  <Switch value={hoursTillStart < 24}>
                    <ShortBookingPaymentPage
                      case={true}
                      confirmUntil={confirmUntil}
                      deposit={deposit}
                      hoursTillStart={hoursTillStart}
                    />
                    <LongBookingPaymentPage confirmUntil={confirmUntil} deposit={deposit} />
                  </Switch>
                </>
              </Switch>

              {error ? (
                <div className="w-100 mt-5">
                  <PaymentMethodSection
                    className={styles.paymentSection}
                    clientToken={clientToken}
                    country={region}
                    name="paymentMethod"
                    title="booking.paymentInfo.payment.title"
                  />
                </div>
              ) : null}

              <FormikSubmitButton className="btn-lg mt-5">
                <T
                  context={isInstantBooking ? 'instantBooking' : null}
                  data={{ amount: totalPrice }}
                  id="booking.paymentInfo.submit"
                />
              </FormikSubmitButton>
            </div>
          </div>
        </Form>
      </FormikProvider>
    </main>
  );
}

function InstantBookingPaymentPage({ cancelUntil }: { cancelUntil: string } & SwitchChildrenProps<boolean>) {
  return (
    <List icon="round-info" success>
      <ListItem>
        <T context="instantBooking" html id="booking.paymentInfo.processedAfterConfirm" />
      </ListItem>
      <ListItem className="mt-1">
        <T context="instantBooking" html id="booking.paymentInfo.creditCheck" />
      </ListItem>
      <ListItem className="mt-4">
        <T data={{ cancelUntil }} html id="booking.paymentInfo.cancellation" />
      </ListItem>
    </List>
  );
}

function ShortBookingPaymentPage({
  hoursTillStart,
  confirmUntil,
  deposit,
}: {
  hoursTillStart: number;
  confirmUntil: string;
  deposit: string;
} & SwitchChildrenProps<boolean>) {
  return (
    <List icon="round-info" success>
      <ListItem>
        <T
          data={{ confirmUntil, count: hoursTillStart, hours: hoursTillStart }}
          html
          id="booking_payment_info_text_1_new"
        />
      </ListItem>
      <ListItem className="mt-1">
        <T html id="booking.paymentInfo.processedAfterConfirm" />
      </ListItem>
      <ListItem className="mt-1">
        <T html id="booking.paymentInfo.creditCheck" />
      </ListItem>
      <ListItem className="mt-4">
        <T data={{ price: deposit }} html id="booking.paymentInfo.deposit" />
      </ListItem>
    </List>
  );
}

function LongBookingPaymentPage({ confirmUntil, deposit }: { confirmUntil: string; deposit: string }) {
  return (
    <List icon="round-info" success>
      <ListItem>
        <T data={{ confirmUntil }} html id="booking.paymentInfo.confirmUntil" />
      </ListItem>
      <ListItem className="mt-1">
        <T html id="booking.paymentInfo.processedAfterConfirm" />
      </ListItem>
      <ListItem className="mt-1">
        <T html id="booking.paymentInfo.creditCheck" />
      </ListItem>
      <ListItem className="mt-4">
        <T data={{ price: deposit }} html id="booking.paymentInfo.deposit" />
      </ListItem>
    </List>
  );
}
