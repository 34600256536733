import React from 'react';
import { useSelector } from 'react-redux';

import { useOnLoadTracking } from '../../hooks/useTracking';
import { filtersToQueryString } from '../../model/SearchFilters';
import RouteEnum from '../../RouteEnum';
import { formatDatelike, hoursTill } from '../../services/DateTime';
import Tracking from '../../services/Tracking';
import type { ReduxState } from '../../store';
import { LightningOutlinedIcon } from '../Icons/LightningOutlinedIcon';
import { StaticImage } from '../Image';
import Link from '../Link';
import List, { ListItem } from '../List';
import Switch, { type SwitchChildrenProps } from '../Switch';
import T from '../Translate';

import styles from './order.module.scss';

export default function OrderSuccessPage(_: SwitchChildrenProps<number>) {
  const { instant_booking, ...props } = useSelector((store: ReduxState) => ({
    instant_booking: store.order.trip.vehicle.instant_booking,
    hoursTillStart: hoursTill(store.order.trip.date_from),
    confirmUntil: formatDatelike(store.order.trip.confirm_to, 'd.M.yyyy HH:mm'),
    query: filtersToQueryString(store.rentCar.filters, store.rentCar.initialFilters),
  }));

  return (
    <main className="main-container container--top container--bottom">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-10 offset-lg-1 col-xl-6 offset-xl-3 d-flex flex-column align-items-center">
            <Switch value={instant_booking}>
              <OrderSuccessInstantPage {...props} case={true} />
              <>
                <Switch value={props.hoursTillStart <= 24}>
                  <OrderSuccessFutureShortPage {...props} case={true} />
                  <OrderSuccessFutureLongPage {...props} case={false} />
                </Switch>
              </>
            </Switch>
          </div>
        </div>
      </div>
    </main>
  );
}

export function OrderSuccessInstantPage(_: SwitchChildrenProps<boolean>) {
  useOnLoadTracking(() => Tracking.track('BOOKING_SUCCESS_VIEWED', 'booking_instant'));

  return (
    <div className={styles.success}>
      <StaticImage alt="illustration" name="booking-success-instant" width={300} />
      <T as="h1" className="mt-5 mb-5 text-center" context="instantBooking" id="booking.success.title" multiline />
      <List icon="round-info" success>
        <ListItem>
          <T context="instantBooking" html id="booking.success.text.1" />
        </ListItem>
        <ListItem>
          <T context="instantBooking" html id="booking.success.text.2" />
        </ListItem>
      </List>
      <Link className="btn btn-primary btn-block mt-5" to={RouteEnum.HOMEPAGE}>
        <T context="instantBooking" id="booking.success.continue" />
      </Link>
    </div>
  );
}

interface FuturePageProps {
  confirmUntil: string;
  hoursTillStart: number;
  query: string;
}

export function OrderSuccessFutureShortPage({
  confirmUntil,
  hoursTillStart,
  query,
}: FuturePageProps & SwitchChildrenProps<boolean>) {
  useOnLoadTracking(() => Tracking.track('BOOKING_SUCCESS_VIEWED', 'booking_short'));

  return (
    <div className={styles.success}>
      <StaticImage alt="illustration" name="booking-success" width={300} />
      <T as="h1" className="mt-5 mb-5 text-center" context="short" id="booking.success.title" multiline />
      <List icon="round-info" success>
        <ListItem>
          <T
            data={{
              confirmUntil,
              count: hoursTillStart,
              hours: hoursTillStart,
            }}
            html
            id="trip_booking_success_text_1"
          />
        </ListItem>
        <ListItem>
          <T html id="trip_booking_success_short_text_2_new" />
        </ListItem>
      </List>

      <div className={styles.hint}>
        <div className={styles.title}>
          <LightningOutlinedIcon className={styles.icon} />
          <T html id="trip_booking_success_soon_title" />
        </div>
        <T as="p" className="mb-0" id="trip_booking_success_soon_description" />
      </div>

      <Link
        className="btn btn-primary btn-block mt-5"
        onClick={() => Tracking.track('BOOKING_SUCCESS_REPEAT_CLICKED', 'booking_short')}
        search={query}
        to={RouteEnum.RENT_CAR}
      >
        <T context="short" id="booking.success.requestMore" />
      </Link>
      <Link
        className="btn btn-primary-light btn-block mt-2"
        onClick={() => Tracking.track('BOOKING_SUCCESS_WAIT_CLICKED', 'booking_short')}
        to={RouteEnum.HOMEPAGE}
      >
        <T context="short" id="booking.success.continue" />
      </Link>
    </div>
  );
}

export function OrderSuccessFutureLongPage({ confirmUntil, query }: FuturePageProps & SwitchChildrenProps<boolean>) {
  useOnLoadTracking(() => Tracking.track('BOOKING_SUCCESS_VIEWED', 'booking_long'));

  return (
    <div className={styles.success}>
      <StaticImage alt="illustration" name="booking-success" width={300} />
      <T as="h1" className="mt-5 mb-5 text-center" context="long" id="booking.success.title" multiline />
      <List icon="round-info" success>
        <ListItem>
          <T context="long" data={{ confirmUntil }} html id="booking.success.text.1" />
        </ListItem>
        <ListItem>
          <T context="long" html id="booking.success.text.2" />
        </ListItem>
        <ListItem>
          <T context="long" html id="booking.success.text.3" />
        </ListItem>
      </List>
      <Link
        className="btn btn-primary btn-block mt-5"
        onClick={() => Tracking.track('BOOKING_SUCCESS_WAIT_CLICKED', 'booking_long')}
        to={RouteEnum.HOMEPAGE}
      >
        <T context="long" id="booking.success.continue" />
      </Link>
      <Link
        className="btn btn-primary-light btn-block mt-2"
        onClick={() => Tracking.track('BOOKING_SUCCESS_REPEAT_CLICKED', 'booking_long')}
        search={query}
        to={RouteEnum.RENT_CAR}
      >
        <T context="long" id="booking.success.requestMore" />
      </Link>
    </div>
  );
}
