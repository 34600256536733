import React from 'react';
import cn from 'classnames';

import styles from './list.module.scss';

interface ListItemProps extends React.HTMLAttributes<HTMLDivElement> {
  icon?: string;
  primary?: boolean;
  success?: boolean;
  iconClassName?: string;
  children: React.ReactNode;
}

interface ListProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'children'> {
  icon?: string;
  primary?: boolean;
  success?: boolean;
  iconClassName?: string;
  children: React.ReactElement<ListItemProps>[];
}

export default function List({ icon, children, primary, success, iconClassName, ...props }: ListProps) {
  return (
    <div {...props} className={cn(styles.list, { primary, success }, props.className)}>
      {React.Children.map(children, child => {
        return React.cloneElement(child, {
          icon,
          primary,
          success,
          iconClassName,
          ...child.props,
        });
      })}
    </div>
  );
}

export function ListItem({ icon, primary, success, iconClassName, children, ...props }: ListItemProps) {
  return (
    <div {...props} className={cn(styles.listItem, { primary, success }, props.className)}>
      <i className={cn(`icon icon-${icon}`, iconClassName)} />
      <div>{children}</div>
    </div>
  );
}
