import React, { useEffect } from 'react';

export interface SwitchChildrenProps<T> {
  case?: T | T[];
}

interface SwitchProps<T> {
  value: T;
  children: React.ReactNode[];
  scrollToTop?: boolean;
}

function matchesValue<T>(value: T, caseValue: T | T[]) {
  if (caseValue === undefined || caseValue === null) return false;
  if (Array.isArray(caseValue)) {
    return caseValue.includes(value);
  }
  return caseValue === value;
}

function Switch<T>(props: SwitchProps<T>) {
  let result: React.ReactElement<SwitchChildrenProps<T>> = null;
  React.Children.forEach(props.children, (child: React.ReactElement<SwitchChildrenProps<T>>, index) => {
    if (
      !result &&
      (matchesValue(props.value, child.props.case) || (!child.props.case && index === props.children.length - 1))
    ) {
      result = child;
    }
  });

  useEffect(() => {
    if (!props.scrollToTop) return;
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [props.value, props.scrollToTop]);

  if (!result) return null;
  return React.cloneElement(result);
}

interface CaseProps<T> {
  case?: T | T[];
  children: React.ReactNode;
}

export function Case<T>({ children }: CaseProps<T>) {
  return children as JSX.Element;
}

Switch.Case = Case;

export default Switch;
