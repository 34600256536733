import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { formatPrice, type PriceType } from '../../model/Price';
import type { ReduxState } from '../../store';
import T from '../Translate';

interface DepositProps {
  deposit: PriceType;
  ns: string;
  className?: string;
  ownInsurance?: boolean;
}

export default function Deposit({ deposit, ns, className, ownInsurance }: DepositProps) {
  const lang = useSelector((store: ReduxState) => store.app.language);

  return (
    <div className={classNames('bg-light rounded d-flex p-4', className)}>
      <i className="icon icon-xl icon-vault mr-3" />
      <T
        context={ownInsurance ? 'ownInsurance' : undefined}
        data={{ price: formatPrice(deposit, lang) }}
        html
        id={`${ns}.deposit`}
      />
    </div>
  );
}
