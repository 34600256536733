import React from 'react';

import type { PriceType } from '../../model/Price';
import { Checkbox } from '../Input/Checkbox';
import Price from '../Price';
import T from '../Translate';

import styles from './products.module.scss';

interface Props {
  name: string;
  value: string;
  checked: boolean;
  price: PriceType;
  description?: string;
  recommended?: boolean;

  onChange(): void;
}

export function ProductCheckbox(props: Props) {
  return (
    <Checkbox
      checked={props.checked}
      className={styles.checkbox}
      name={`product-${props.value}`}
      onChange={props.onChange}
      value={props.value}
    >
      <div className={styles.label}>
        <span>{props.name}</span>
        <Price as="span" price={props.price} />
      </div>
      {props.recommended ? <T className={styles.recommended} id="insurance_recommended" /> : null}
      {props.description ? <span className={styles.description}>{props.description}</span> : null}
    </Checkbox>
  );
}
