import React, { useState } from 'react';
import cn from 'classnames';

import { OrderProductType, type TripInsurance } from '../../model/OrderProduct';
import Tracking from '../../services/Tracking';
import type { OrderChangeData } from '../../store/order';
import { Radio } from '../Input/Radio';
import ProductCard from '../Insurance/ProductCard';
import Modal from '../Modal';
import Price from '../Price';
import T from '../Translate';

import styles from '../Insurance/products.module.scss';
import orderStyles from './order.module.scss';

interface Props {
  insurances: TripInsurance[];
  selected: number;

  onChange(data: OrderChangeData): unknown;
}

export default function InsuranceSection(props: Props) {
  const hasOwnInsurance = props.insurances.every(it => it.type === OrderProductType.OWN_INSURANCE);
  const [opened, setOpened] = useState(false);

  const handleChange = (insurance: TripInsurance) => {
    props.onChange({ selectedMandatoryInsurance: insurance.id });
  };

  return (
    <section>
      <T as="h4" context={hasOwnInsurance ? 'ownInsurance' : null} id="booking.summary.insurance.title" />
      <div className={orderStyles.productList}>
        {props.insurances.map(insurance => (
          <Radio
            checked={props.selected === insurance.id}
            className={cn(styles.radio, 'transparent indicator-left')}
            key={insurance.type}
            name="insurance"
            onChange={() => handleChange(insurance)}
            value={insurance.type}
          >
            <div className={styles.label}>
              <span>{insurance.name}</span>
              <Price as="span" price={insurance.price} />
            </div>
            {insurance.recommended ? <T className={styles.recommended} id="insurance_recommended" /> : null}
          </Radio>
        ))}
      </div>

      <button
        className="btn btn-link mt-3"
        onClick={() => {
          setOpened(true);
          Tracking.track('BOOKING_INSURANCE_DETAIL_CLICKED', hasOwnInsurance ? 'own' : 'commercial');
        }}
        type="button"
      >
        <T as="span" context={hasOwnInsurance ? 'ownInsurance' : null} id="booking.summary.insurance.compare" />
        <i className="icon icon-arrow-right text-decoration-none text-muted ml-3" />
      </button>

      <Modal
        closeModal={() => setOpened(false)}
        header={
          <h5 className="flex-grow-1 text-center">
            <T context={hasOwnInsurance ? 'ownInsurance' : null} id="booking.insurance.detail.title" />
          </h5>
        }
        isOpen={opened}
        onOpen={() => Tracking.track('BOOKING_INSURANCE_DETAIL_VIEWED', hasOwnInsurance ? 'own' : 'commercial')}
        size="lg"
      >
        <div className="modal-body">
          <T
            as="p"
            className="text-center text-muted mb-5"
            context={hasOwnInsurance ? 'ownInsurance' : null}
            id="booking.insurance.detail.description"
            multiline
          />
          <div className={orderStyles.productModalBody}>
            {props.insurances.map(insurance => (
              <ProductCard
                deposit={insurance.deposit}
                description={insurance.description}
                description_list={insurance.description_list}
                key={insurance.id}
                onClick={() => handleChange(insurance)}
                onLinkClick={() => Tracking.track('BOOKING_INSURANCE_DETAIL_LINK_CLICKED', insurance)}
                price={insurance.price}
                recommended={insurance.recommended}
                selected={props.selected === insurance.id}
                title={insurance.name}
                type={insurance.type}
              />
            ))}
          </div>
        </div>
      </Modal>
    </section>
  );
}
