import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { string, ValidationError } from 'yup';

import { formatPrice } from '../../model/Price';
import type { TripPricingData } from '../../model/Trip';
import type { ReduxState } from '../../store';
import { applyDiscountToOrder } from '../../store/order';
import Detached from '../Detached';
import { Checkbox } from '../Form/CheckboxField';
import { Input } from '../Form/Field';
import FormGroup from '../Form/FormGroup';
import { SubmitButton } from '../Form/SubmitButton';
import Modal from '../Modal';
import T from '../Translate';

interface ApplySalesCodeModalProps {
  opened: boolean;
  onClose(): unknown;
}

function ApplySalesCodeModal(props: ApplySalesCodeModalProps) {
  const dispatch: DispatchFunction = useDispatch();
  const [salesCode, setSalesCode] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const schema = string().required('global.required');

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      const value = await schema.validate(salesCode);
      setSubmitting(true);
      await dispatch(applyDiscountToOrder(value));
      props.onClose();
    } catch (error) {
      if (ValidationError.isError(error)) {
        setError(error.errors[0]);
      } else {
        setError(error.message);
        setSubmitting(false);
      }
    }
  };

  useEffect(() => {
    if (props.opened) return;
    setSubmitting(false);
    setSalesCode('');
    setError(null);
  }, [props.opened]);

  if (!props.opened) return null;

  return (
    <Detached container="sales-code-modal">
      <Modal
        closeModal={props.onClose}
        header={
          <h5 className="flex-grow-1 text-center">
            <T id="booking.discounts.salesCode.title" />
          </h5>
        }
        size="md"
      >
        <div className="modal-body">
          <form onSubmit={onSubmit}>
            <T as="p" id="booking.discounts.salesCode.description" />
            <FormGroup error={error}>
              <Input onChange={setSalesCode} placeholder="booking.discounts.salesCode.placeholder" value={salesCode} />
            </FormGroup>
            <SubmitButton className="btn-block" loading={submitting}>
              <T id="booking.discounts.salesCode.submit" />
            </SubmitButton>
          </form>
        </div>
      </Modal>
    </Detached>
  );
}

interface Props {
  useReward: boolean;
  salesCode: string;

  onChange(data: Partial<TripPricingData>): Promise<void> | void;
}

export default function DiscountsSection(props: Props) {
  const { userReward, lang } = useSelector((store: ReduxState) => ({
    userReward: store.user.reward,
    lang: store.app.language,
  }));
  const [openedModal, setOpenedModal] = useState(false);

  const handleSalesCodeClick = useCallback(() => {
    if (props.salesCode) {
      props.onChange({ saleCode: null });
      return;
    }
    setOpenedModal(true);
  }, [props.salesCode]);

  const canUseReward = userReward.amount > 0;

  return (
    <section>
      <T as="h4" id="booking.summary.discounts.title" />

      <Checkbox
        checked={!!props.useReward}
        description={!canUseReward ? <T id="booking.summary.discounts.reward.description" /> : null}
        disabled={!canUseReward}
        onChange={() => props.onChange({ useReward: !props.useReward })}
        primary
      >
        <T data={{ rewardPrice: formatPrice(userReward, lang) }} id="booking.summary.discounts.reward" />
      </Checkbox>

      <Checkbox
        checked={!!props.salesCode}
        description={props.salesCode ? <span className="font-weight-bold">{props.salesCode}</span> : null}
        onChange={handleSalesCodeClick}
        primary
      >
        <T id="booking.summary.discounts.promoCode" />
      </Checkbox>

      <T as="p" className="mt-3 text-muted" id="order_discount_not_apply_to_insurance" />

      <ApplySalesCodeModal onClose={() => setOpenedModal(false)} opened={openedModal} />
    </section>
  );
}
