import React from 'react';

import { TextAreaInput } from '../Input/Input';
import type { FieldError } from '../Input/useField';
import T, { useTranslation } from '../Translate';

interface Props {
  value: string;
  error?: FieldError;

  onChange(value: string): void;
}

export default function MessageSection(props: Props) {
  const { t } = useTranslation();

  return (
    <section>
      <T as="h4" id="booking.summary.message.title" />
      <T as="p" id="booking.summary.message.description" />
      <TextAreaInput
        error={props.error}
        onChange={props.onChange}
        placeholder={t('booking.summary.message.placeholder')}
        rows={3}
        value={props.value}
      />
    </section>
  );
}
